import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';



const Carousel = ({ tattoos }) => {

  
  console.log('Tattoos:', tattoos); 
  return (
    <div id="tattoosCarousel" className="p-5 container carousel slide" data-bs-ride="carousel">
      <div className="carousel-inner">
        {tattoos.map((tattoo, index) => (
          <div key={tattoo.id} className={`carousel-item ${index === 0 ? 'active' : ''}`}>
            <img src={tattoo.imageURL} className="d-block rounded w-100 carousel-img" alt={tattoo.title} />
          </div>
        ))}
      </div>
      <button className="carousel-control-prev" type="button" data-bs-target="#tattoosCarousel" data-bs-slide="prev">
        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Previous</span>
      </button>
      <button className="carousel-control-next" type="button" data-bs-target="#tattoosCarousel" data-bs-slide="next">
        <span className="carousel-control-next-icon" aria-hidden="true"></span>
        <span className="visually-hidden">Next</span>
      </button>
    </div>
  );
};

export default Carousel;
