import React, { useEffect, useRef, useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Carousel from './Carousel';
import { useTattoos } from '../../hooks/portfolio';
import ImportantMessage from '../Admin/ImportantMessage';
import { Link } from 'react-router-dom';


const Layout = ({ user }) => {
  const { tattoos, isLoading } = useTattoos();
  const [contentHeight, setContentHeight] = useState(0);
  const contentRef = useRef(null);

  useEffect(() => {
    if (contentRef.current) {
      const newContentHeight = contentRef.current.clientHeight;
      setContentHeight(newContentHeight);
    }
  }, []);


  const yellowBoxStyle = {
    backgroundColor: "rgba(240, 191, 97, 0.65)", // Yellow background color at 65% opacity
    padding: "5%",
    borderRadius: "10px",
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.3)", // Black box shadow
  };

  const buttonAnimation = {
    animation: "resizing 3s infinite alternate",
  };

  return (
    <div>
      <Header contentHeight={contentHeight} />
      <div id="home" ref={contentRef} >
      <ImportantMessage />

        <div id="welcome-hero">
          <div className="title groovy-font">
            <div className="title-inner textAnimate">
              <div className="cafe">
                <div className="cafe-inner">This is</div>
              </div>
              <div className="mozart">
                <div className="mozart-inner">GroovTam</div>
              </div>
            </div>
          </div>

          <div className="image">
            {isLoading ? <div>Loading...</div> : <Carousel tattoos={tattoos} />}
          </div>
        </div>

        <div style={yellowBoxStyle} className="container">
          <div className="text-center">
            <p className="groovy-font fs-1">Click Here to Book an Appointment</p>
            <button className='btn btn-warning rounded' style={buttonAnimation}>
              <Link to="/contact" className="fs-5 p-2 text-dark fw-bold text-decoration-none">Book Now</Link>
            </button>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
