import React from 'react';
import { useTattoos } from '../../hooks/portfolio';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { deleteDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../lib/firebase'; 

const EditTattoos = () => {
  const { tattoos, isLoading } = useTattoos();

  const handleDelete = async (tattooId) => {
    const res = window.confirm(`Are you sure you want to delete this tattoo ${tattooId}?`);
  
    if (res) {
      try {
        // Query for the tattoo document
        const tattoosQuery = query(collection(db, 'tattoos'), where('id', '==', tattooId));
        const querySnapshot = await getDocs(tattoosQuery);
  
        // Get the document reference from the query snapshot
        if (!querySnapshot.empty) {
          const tattooDocRef = querySnapshot.docs[0].ref;
  
          // Delete the tattoo document
          await deleteDoc(tattooDocRef);
  
          console.log(`Tattoo with ID ${tattooId} deleted successfully`);
  
          // Refresh the page
          window.location.reload();
        } else {
          console.log(`Tattoo with ID ${tattooId} not found`);
        }
      } catch (error) {
        console.error('Error deleting tattoo:', error);
      }
    }
  };
  
  
  

  return (
    <div className="text-center container my-4">
      <h1>Edit/Delete Tattoos</h1>
      {isLoading ? (
        <p>Loading...</p>
      ) : (
        <div className="row row-cols-1 row-cols-md-3 g-4">
          {tattoos.map((tattoo) => (
            <div key={tattoo.id} className="col">
              <div className="card">
                <img src={tattoo.imageURL} className="card-img-top" alt={tattoo.title} />
                <div className="card-body">
                  <h5 className="card-title">{tattoo.title}</h5>
                  <p className="card-text">{tattoo.description}</p>
                  <p className="card-text">{tattoo.id}</p>
                  <button
                    className="btn btn-danger"
                    onClick={() => handleDelete(tattoo.id)}
                  >
                    <FontAwesomeIcon icon={faTrash} /> Delete
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default EditTattoos;
