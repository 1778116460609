import React, { useState } from 'react';
import useAddMessage from '../../hooks/addMessage';

const AddImportantMessage = () => {
     // State variables to hold form input values
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [imageFile, setImageFile] = useState(null);

  // Using the custom hook to handle adding tattoos
  const { addMessage, isAdding, error } = useAddMessage();

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (title && message && imageFile) {
      // Call the addMessage function from the custom hook
      await addMessage(title, message, imageFile);
      // Clear the form fields after successful submission
      setTitle('');
      setMessage('');
      setImageFile(null);
    }
  };
    return (
        <div className='text-center my-3'>
          <h2>Add Message</h2>
          <form onSubmit={handleSubmit}>
            <div className="mb-3">
              <label htmlFor="title" className="form-label">Title</label>
              <input
                type="text"
                className="form-control"
                id="title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="description" className="form-label">Message</label>
              <textarea
                className="form-control"
                id="message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="image" className="form-label">Image</label>
              <input
                type="file"
                className="form-control"
                id="image"
                accept="image/*"
                onChange={(e) => setImageFile(e.target.files[0])}
                required
              />
            </div>
            {/* Submit button that is disabled during the adding process */}
            <button type="submit" className="btn btn-primary" disabled={isAdding}>
              {isAdding ? 'Adding...' : 'Submit'}
            </button>
            {/* Display error message if there's an error */}
            {error && <p className="text-danger mt-2">{error.message}</p>}
          </form>
        </div>
      );
}

export default AddImportantMessage
