import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <footer className="py-4 groovy-font" style={{ background: '#f0bf61'}}>
      <div className="container">
        <div className="row">
          {/* Column 1: Email and Phone Number */}
          <div className="col-md-4 text-center mb-3 mb-md-0">
            <p>
              <strong>Phone: </strong> 
              <a href="tel:226-350-0945" className="text-dark text-decoration-none" title="Call us at 226-350-0945">
                226-350-0945
              </a>
            </p>
            <p>
              <strong>Email: </strong> 
              <a href="mailto:groovtam@gmail.com" className="text-decoration-none text-dark" title="Email us at groovtam@gmail.com">
                groovtam@gmail.com
              </a>
            </p>
          </div>

          {/* Column 2: Website Name */}
          <div className="col-md-4 text-center">
            <span className="h5 fw-bold mt-3">GROOVTAMTATTOOS</span>
          </div>

          {/* Column 3: Social Media Links */}
          <div className="col-md-4 mt-3 text-center">
            {/* Instagram Icon */}
            <a href="https://www.instagram.com/groovtam/" target="_blank" rel="noreferrer" className="text-decoration-none text-dark me-3" aria-label="Instagram">
              <FontAwesomeIcon icon={faInstagram} size="2x" />
            </a>
            
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
