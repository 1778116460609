import React from 'react';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

const Form = () => {
  return (
    <div id="contact">
      <Header />
      <div className="container rounded my-3 mt-5" style={{ backgroundColor: "#f0bf61", padding: "3%" }}>
        <h1 className='groovy-font text-center'>Send me a message!</h1>
        <h6 className='handwriting text-center'>I usually reply within two business days.</h6>
        <form method="POST" action="https://formspree.io/f/mknlbnnk" className="my-3 handwriting">
          <div className="mb-3">
            <label htmlFor="name" className="form-label">Name<span className="required">*</span></label>
            <input type="text" className="form-control" id="name" name='name' placeholder='Jenny Meade' required />
          </div>
          <div className="mb-3">
            <label htmlFor="phone" className="form-label">Phone Number<span className="required">*</span></label>
            <input type="tel" className="form-control" id="phone" name='phone' placeholder='(519)-867-5309' required />
          </div>
          <div className="mb-3">
            <label htmlFor="instagram" className="form-label">Instagram Handle</label>
            <input type="text" className="form-control" id="instagram" name='instagram' placeholder='@username' />
          </div>
          <div className="mb-3">
            <label htmlFor="email" className="form-label">Email</label>
            <input type="email" className="form-control" id="email" name='email' placeholder='username@email.com'/>
          </div>
          <div className="mb-3">
            <label htmlFor="description" className="form-label">Description<span className="required">*</span></label>
            <textarea className="form-control" id="description" name='description' placeholder='Give me a general description of what you want me to tattoo for you' required />
          </div>
          <button type="submit" className="btn btn-danger text-center">Send Message</button>
        </form>
        <div className="d-flex justify-content-center">
            <iframe
                className='rounded'
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2949.6322479400137!2d-82.96950522267707!3d42.32904193705874!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x883b2b36c8b23069%3A0x6a646dd34b8a9d6d!2s5854%20Wyandotte%20St%20E%2C%20Windsor%2C%20ON%20N8S%201M8!5e0!3m2!1sen!2sca!4v1693411706031!5m2!1sen!2sca"
                width="800"
                height="600"
                style={{ border: "0" }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
                title="Google Map"
            ></iframe>
        </div>
      </div>
      
      <Footer />
    </div>
  );
}

export default Form;
