import { useEffect, useState } from 'react';
import { Timestamp } from 'firebase/firestore';
import { onSnapshot, getDocs, collection, doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../lib/firebase'; // Assuming 'db' is your Firestore instance

// ...


const useAddMessage = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(null);

  const addMessage = async (title, message, imageFile) => {
    setIsAdding(true);
  
    try {
      // Get the number of documents in the 'messages' collection
      const messagesCollectionRef = collection(db, 'messages');
      const messagesCollectionSnapshot = await getDocs(messagesCollectionRef);
      const newId = messagesCollectionSnapshot.size + 1;
  
      // Create a Firestore Timestamp for the current time
      const timestamp = Timestamp.fromDate(new Date());
  
      // Add message to Firestore collection with the new id and timestamp
      const messageRef = doc(db, 'messages', newId.toString());
      await setDoc(messageRef, {
        id: newId,
        title,
        message,
        date: timestamp, // Add the timestamp here
      });
  
      // Upload image to Firebase Storage and get download URL
      const imageFileName = `${title.replace(/\s+/g, '_')}_${Date.now()}`;
      const imageRef = ref(storage, `message/${imageFileName}`);
      await uploadBytes(imageRef, imageFile);
      const imageURL = await getDownloadURL(imageRef);
  
      // Update Firestore document with imageURL
      await setDoc(messageRef, { imageURL }, { merge: true });
  
      setIsAdding(false);
      setError(null);
    } catch (error) {
      console.error('Error adding message:', error);
      setError(error);
      setIsAdding(false);
    }
  };

  return { addMessage, isAdding, error };
};


export default useAddMessage;






