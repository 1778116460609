import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/auth';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';
import { auth } from '../../lib/firebase'; // Import the 'auth' instance from your Firebase configuration
import EditTattoos from './EditTattoos';
import AddTattoo from './AddTattoo';
import ImportantMessage from './ImportantMessage';
import AddImportantMessage from './AddImportantMessage';

const Admin = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const redirectToLogin = () => {
    // Redirect to "/" upon successful login
    window.location.href = '/login';
  };

  // Use useEffect to log user information after the initial render
  useEffect(() => {
    const checkUserAuth = async () => {
      const unsubscribe = auth.onAuthStateChanged(async (user) => {
        if (user) {
          console.log("signed in admin", user.uid);
        } else {
          navigate('/');
        }
      });

      return () => unsubscribe();
    };

    checkUserAuth();
  }, []); // Empty dependency array to run the effect only once

  const [activeTab, setActiveTab] = useState('editTattoos');

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Header />
      <div className="container  mt-5 handwriting">
        <div className="btn-group mt-5">
          <button
            className={`btn mx-1 ${activeTab === 'editTattoos' ? 'btn-primary' : 'btn-secondary'}`}
            onClick={() => handleTabChange('editTattoos')}
          >
            Delete Tattoos
          </button>
          <button
            className={`btn mx-1 ${activeTab === 'addTattoo' ? 'btn-primary' : 'btn-secondary'}`}
            onClick={() => handleTabChange('addTattoo')}
          >
            Add Tattoo
          </button>
          <button
            className={`btn mx-1 ${activeTab === 'addMessage' ? 'btn-primary' : 'btn-secondary'}`}
            onClick={() => handleTabChange('addMessage')}
          >
            Add Message
          </button>
        </div>

        {/* Display the selected component based on the active tab */}
        {activeTab === 'editTattoos' && <EditTattoos />}
        {activeTab === 'addTattoo' && <AddTattoo />}
        {activeTab === 'addMessage' && <AddImportantMessage />}
      </div>
      


      <Footer />
    </div>
  );
};

export default Admin;
