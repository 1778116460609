import React, { useState } from 'react';
import useMessages from '../../hooks/useMessages'; // Import the custom hook

const ImportantMessage = () => {
  const messages = useMessages(); // Use the custom hook to get messages
  const [closedMessages, setClosedMessages] = useState([]);

  const handleCloseMessage = (index) => {
    setClosedMessages([...closedMessages, index]);
  };

  // Sort messages in reverse chronological order
  const sortedMessages = [...messages].sort((a, b) => b.date - a.date);

  return (
    <div className="p-1 mt-5 container rounded" style={{ maxWidth: '750px', backgroundColor: '#f0bf61' }}>
      {sortedMessages.map((message, index) => (
        !closedMessages.includes(index) && (
          <div key={index} className="card mb-3 position-relative bg-transparent border-0">
            <button
              className="position-absolute text-danger text-decoration-none top-0 fs-4 end-0 m-2 btn btn-link"
              onClick={() => handleCloseMessage(index)}
            >
              <strong>X</strong>
            </button>
            <div className="card-body d-flex">
              <img
                src={message.imageURL}
                className="rounded me-3"
                alt="test"
                width="25%"
              />
              <div>
                <h5 className='card-title groovy-font'>{message.title}</h5>
                <h6 className="mb-2 text-muted fw-bold handwriting">
                  {message.date && message.date.toDate().toLocaleString()}
                </h6>
                <p className='card-text handwriting fs-5'>{message.message}</p>
              </div>
            </div>
          </div>
        )
      ))}
    </div>
  );
};

export default ImportantMessage;
