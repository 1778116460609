// useMessages.js
import { useState, useEffect } from 'react';
import { collection, getDocs } from 'firebase/firestore'; // Import Firestore functions
import { db } from '../lib/firebase'; // Import your Firestore instance

const useMessages = () => {
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    const messagesRef = collection(db, 'messages'); // Assuming 'messages' is your collection name

    const fetchMessages = async () => {
      const querySnapshot = await getDocs(messagesRef);
      const messagesData = querySnapshot.docs.map((doc) => doc.data());
      setMessages(messagesData);
    };

    fetchMessages();
  }, []);

  return messages;
};

export default useMessages;
