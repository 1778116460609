import React, {useState, useEffect, useRef, useLayoutEffect} from 'react';
import { getFirestore, collection, getDocs } from 'firebase/firestore';
import { Modal } from 'react-bootstrap';
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

const TattooList = () => {
  const [tattoos, setTattoos] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedTattoo, setSelectedTattoo] = useState(null);
  const [contentHeight, setContentHeight] = useState(0);

  useEffect(() => {
    const fetchTattoos = async () => {
      try {
        const db = getFirestore();
        const querySnapshot = await getDocs(collection(db, 'tattoos'));
        const tattooData = querySnapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));
        console.log('Fetched data:', tattooData); // Check the fetched data
        setTattoos(tattooData);
      } catch (error) {
        console.error('Error fetching tattoos:', error);
      }
    };

    fetchTattoos();
  }, []);

  console.log('Tattoos state:', tattoos); // Check the state containing fetched data

  const contentRef = useRef(null);
  useLayoutEffect(() => {
    if (contentRef.current) {
      const newContentHeight = contentRef.current.clientHeight;
      setContentHeight(newContentHeight);
    }
  }, [tattoos]);

  const openModal = (tattoo) => {
    setSelectedTattoo(tattoo);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
    setSelectedTattoo(null);
  };


  return (
    <div>
      <Header  contentHeight={contentHeight} />
        <section id="portfolio" ref={contentRef}>
          <div
            style={{height: 'fit-content'}}
          >
            <div className="row justify-content-around">
              <div className="col p-0 text-center">
                <h1 className="mb-4 groovy-font funky-shadow portfolio-title">Check Out My Work</h1>
                {/* Grid layout for tattoo pieces */}
                <div className="row rowOverride">

                  {tattoos.map((tattoo) => (
                    <div className="col-md-4 portfolio-item" key={tattoo.id}>
                      <svg style={{ position: 'absolute', top: 0, left: 0 }}>
                        <clipPath id="flower-clip-path">
                          <circle cx="29%" cy="55%" r="19.9%" />
                          <circle cx="57%" cy="43%" r="19.9%" />
                          <circle cx="76%" cy="80%" r="19.9%" />
                          <circle cx="69%" cy="135%" r="19.9%" />
                          <circle cx="42%" cy="148%" r="19.9%" />
                          <circle cx="24%" cy="113%" r="19.9%" />
                          <circle cx="50%" cy="100%" r="35.1%" /> {/* center circle */}
                        </clipPath>
                      </svg>
                      <div
                        className="portfolio-item-wrapper"
                        onClick={() => openModal(tattoo)}
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title={`${tattoo.title} - ${tattoo.description}`}
                        style={{ position: 'relative', clipPath: 'url(#flower-clip-path)' }}
                      >
                        <div className="portfolio-rotation-wrapper">
                          <img
                              src={tattoo.imageURL}
                              alt={tattoo.title}
                              className="img-fluid rounded"
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                  <Modal show={showModal} onHide={closeModal} centered>
                    <Modal.Body>
                      {selectedTattoo && (
                          <img
                              src={selectedTattoo.imageURL}
                              alt={selectedTattoo.title}
                              className="img-fluid tattooModal"
                          />
                      )}
                    </Modal.Body>
                  </Modal>
                </div>
              </div>
            </div>
          </div>
        </section>
      <Footer />
    </div>
      
  );
};

export default TattooList;