import React, { useState } from 'react';
import useAddTattoo from '../../hooks/portfolio';

const AddTattoo = () => {
  // State variables to hold form input values
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [imageFile, setImageFile] = useState(null);

  // Using the custom hook to handle adding tattoos
  const { addTattoo, isAdding, error } = useAddTattoo();

  // Function to handle form submission
  const handleSubmit = async (event) => {
    event.preventDefault();
    if (title && description && imageFile) {
      // Call the addTattoo function from the custom hook
      await addTattoo(title, description, imageFile);
      // Clear the form fields after successful submission
      setTitle('');
      setDescription('');
      setImageFile(null);
    }
  };

  return (
    <div className='text-center my-3'>
      <h2>Add Tattoo</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label htmlFor="title" className="form-label">Title</label>
          <input
            type="text"
            className="form-control"
            id="title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="description" className="form-label">Description</label>
          <textarea
            className="form-control"
            id="description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            required
          />
        </div>
        <div className="mb-3">
          <label htmlFor="image" className="form-label">Image</label>
          <input
            type="file"
            className="form-control"
            id="image"
            accept="image/*"
            onChange={(e) => setImageFile(e.target.files[0])}
            required
          />
        </div>
        {/* Submit button that is disabled during the adding process */}
        <button type="submit" className="btn btn-primary" disabled={isAdding}>
          {isAdding ? 'Adding...' : 'Submit'}
        </button>
        {/* Display error message if there's an error */}
        {error && <p className="text-danger mt-2">{error.message}</p>}
      </form>
    </div>
  );
}

export default AddTattoo;
