import { useEffect, useState } from 'react';
import { onSnapshot, getDocs, collection, doc, setDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { db, storage } from '../lib/firebase'; // Assuming 'db' is your Firestore instance

// ...

export function useTattoos() {
  const [tattoos, setTattoos] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'tattoos'), (snapshot) => {
      const fetchedTattoos = snapshot.docs.map((doc) => doc.data());
      setTattoos(fetchedTattoos);
      setIsLoading(false);
    });

    return unsubscribe; // Cleanup by unsubscribing from the snapshot listener
  }, []);

  return { tattoos, isLoading };
}


const useAddTattoo = () => {
  const [isAdding, setIsAdding] = useState(false);
  const [error, setError] = useState(null);

  const addTattoo = async (title, description, imageFile) => {
    setIsAdding(true);

    try {
      // Get the number of documents in the 'tattoos' collection
      const tattoosCollectionRef = collection(db, 'tattoos');
      const tattoosCollectionSnapshot = await getDocs(tattoosCollectionRef);
      const newId = tattoosCollectionSnapshot.size + 1;

      // Add tattoo to Firestore collection with the new id
      const tattooRef = doc(db, 'tattoos', newId.toString());
      await setDoc(tattooRef, {
        id: newId,
        title,
        description,
      });

      // Upload image to Firebase Storage and get download URL
      const imageFileName = `${title.replace(/\s+/g, '_')}_${Date.now()}`;
      const imageRef = ref(storage, `tattoos/${imageFileName}`);
      await uploadBytes(imageRef, imageFile);
      const imageURL = await getDownloadURL(imageRef);

      // Update Firestore document with imageURL
      await setDoc(tattooRef, { imageURL }, { merge: true });

      setIsAdding(false);
      setError(null);
    } catch (error) {
      console.error('Error adding tattoo:', error);
      setError(error);
      setIsAdding(false);
    }
  };

  return { addTattoo, isAdding, error };
};


export default useAddTattoo;






