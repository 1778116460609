// Header.js
import React, {useEffect, useState} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom'; //  using React Router for navigation


// Header.js
const Header = ({ contentHeight }) => {
  const borderHeight = contentHeight + 20;
  const [navbarActive, setNavbarActive] = useState(false);

  useEffect(() => {
      const handleScroll = () => {
        if (window.scrollY > 10) {
            setNavbarActive(true);
        }  else {
            setNavbarActive(false);
        }
      };
      window.addEventListener('scroll', handleScroll);
      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <header>
        <div className="aboutBorder-top mobileHidden"></div>
        <div className="aboutBorder-right mobileHidden" style={{ height: `${borderHeight}px` }}></div>
        <div className="aboutBorder-left mobileHidden" style={{ height: `${borderHeight}px` }}></div>

        <nav className={`navbar navbar-expand-lg fixed-top navOverride mobileHidden ${navbarActive ? 'active' : ''}`}>
            <div className="container">
                <Link to="/"
                      className="navbar-brand text-uppercase groovy-font"
                      style={{ fontSize: '1.5em' }}>GroovTamTattoos</Link>

                <div id="navbarSupportedContent" className="collapse navbar-collapse justify-content-end groovy-font">
                    <ul className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <a href="/about" className="nav-link text-uppercase font-weight-bold navLink">About</a>
                        </li>
                        <li className="nav-item">
                            <a href="/portfolio" className="nav-link text-uppercase font-weight-bold">My Work</a>
                        </li>
                        <li className="nav-item">
                         {/*  <a className="nav-link" rel='noreferrer' href="https://docs.google.com/forms/d/e/1FAIpQLSdvQeR9S4ITf9EXPZoqniHC4AVL8-hZjvRL1ULDiFhTD107Cw/viewform?usp=sf_link" target='_blank'>Contact</a> */}
                         <a href="/contact" className="nav-link text-uppercase font-weight-bold">Contact</a>
                        </li>
                        <li className="nav-item">
                            <a href="/login" className="nav-link text-uppercase font-weight-bold">Login</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>

        <div className="mobNav notMobileHidden">
            <input type="checkbox" className="mobNavToggler" />
                <div className="mobNavBurger">
                    <div></div>
                </div>
                <div className="mobNavMenu">
                    <div>
                        <div>
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><a href="/about">About</a></li>
                                <li><a href="/portfolio">My Work</a></li>
                                <li><a href="/contact">Contact Me</a></li>
                                <li><a href="/login">Login</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
        </div>

    </header>
  );
};

export default Header;

