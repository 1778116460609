import React from 'react'
import Header from '../Layout/Header';
import Footer from '../Layout/Footer';

const About = () => {

  return (
    <div id="about"  style={{ background: "#F8E9BB" }}>
        <Header />
      <div className="aboutHero">
        <div className="bg-about">
          <h1>HAMDAH <strong className="groovy-font"> TAMARA</strong></h1>
        </div>
      </div>

      <div className="container">
        <article className="about-box">
          <div className="row row2col">
            <div className="col-md-6 col-left">
              <p className='handwriting fs-3'>
              Hey there, lovely souls and groovy art enthusiasts! I'm Tamara Hamdah, 
              your friendly neighborhood tattoo artist on a mission to infuse your skin 
              with the magic of fun and groovy tattoos! I've loved to make art ever since 
              I could first pick up a pencil, and I've always loved tattoos, getting 
              tattooed, and drawing tattoos. 💐
              </p>
              <p className='handwriting fs-3'>
              Whether you're looking for a fun, cool, groovy tattoo, or something classic, 
              bold, and bright; I'm your girl! When you step into my space, I thrive to make 
              your experience as comfortable and as fun as possible! I love to turn your groovy 
              ideas into fun little tattoos that you'll cherish forever! 🍄
              </p>
              <p className='handwriting fs-3'>
              I invite you to join me in embracing the groovy vibes and turning your skin 
              into a canvas of self-expression! Let's create art, make memories, and groove 
              to the rhythm of life, one tattoo at a time. 🌿
              </p>
            </div>
            <div className="col-md-6 col-right">
          
           
              <p className='handwriting mt-5 fs-3'>
                Stay groovy,
              </p>
              <p className='handwriting mt-1 mb-5 fs-3'>
                Tamara Hamdah 🌼✨
              </p>
            </div>
          </div>
        </article>
      </div>
      <Footer />
    </div>
  )
}

export default About;
